import React from 'react';
import get from 'lodash/get';
import { getMenuSilos } from '../common/utils';
import { ResourceSetProvider } from '../context/ResourceSetContext';
import { ThemeProvider } from 'styled-components';
import { theme, Notice } from '@sygnia/components';
import { SplashSilo } from '@sygnia/components';
import { FooterMenuWrapper } from '../modules/FooterMenuWrapper';
import { HelmetWrapper } from '../modules/HelmetWrapper';
import { TEMPLATE_TYPES } from '../common/constants';

class HomeTemplate extends React.Component {
  render() {
    const siloMenu = get(this.props, 'pageContext.siloMenu');
    const silos = getMenuSilos(siloMenu.menuItems);
    const footerMenu = get(this.props, 'pageContext.footerMenu');
    const structuredData = get(this.props, 'pageContext.structuredData');
    const globalNotices = get(this.props, 'pageContext.globalNotices');

    const summaryObj = {
      summary: {
        title: structuredData.title,
        description: structuredData.description,
      },
      template: TEMPLATE_TYPES.HOME,
    };

    return (
      <ThemeProvider theme={theme}>
        <ResourceSetProvider>
          <HelmetWrapper section={summaryObj} />
          {globalNotices &&
            globalNotices.map((item, idx) => {
              return (
                <Notice
                  key={`globalNotice-Splash${idx}`}
                  meta={
                    item.node.meta
                      ? JSON.parse(item.node.meta.internal.content)
                      : {}
                  }
                  isActive={item.node.isActive}
                  isDismissible={item.node.isDismissable}
                  text={item.node.content.content}
                />
              );
            })}
          <SplashSilo silos={silos} />
          <FooterMenuWrapper menu={footerMenu} />
        </ResourceSetProvider>
      </ThemeProvider>
    );
  }
}

export default HomeTemplate;
